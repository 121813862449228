import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { hardNavigateMiddleware } from '@presentation/shared/router';
import { STORES_URL } from '../shared/constants';
import { editDukanResolver } from '../shared/guards/v2/resolvers/edit-dukan.resolver';
import { DukanPageComponent } from './dukan/dukan-page/dukan-page.component';
import { StoresComponent } from './stores.component';

@NgModule({
  imports: [StoresComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: StoresComponent,
        canActivate: [hardNavigateMiddleware(STORES_URL)],
      },
      {
        path: 'edit-store',
        component: DukanPageComponent,
        canActivate: [editDukanResolver],
      },
      {
        path: 'edit-product/:id',
        component: DukanPageComponent,
        canActivate: [editDukanResolver],
      },
    ]),
  ],
})
export class StoresModule {}
