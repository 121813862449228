<loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<div class="lock-extension-container">
  <div class="heading-content">
    <p class="heading2--bold title">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.TITLE') }}
    </p>
    <p class="caption1--regular">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.SUBTITLE') }}
      ({{ dialogData.productName }})
    </p>
  </div>
  <form>
    <div class="type-selector">
      <mat-radio-group class="type-selector-group">
        <mat-radio-button
          *ngFor="let type of selectionTypes"
          (click)="onTypeSelected(type.value)"
          class="type-details-radio"
          [checked]="type.isActive"
          [value]="type.value"
          [ngClass]="type.isActive ? 'selected-type' : ''"
        >
          <h5 class="body1--medium">{{ type.name }}</h5>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="quantity-field" *ngIf="selectedType !== 'time'">
      <p class="caption1--regular">
        {{
          trans(
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.QUANTITY_LABEL'
          )
        }}
      </p>
      <input name="quantity" [(ngModel)]="requestedQuantity" />
    </div>
    <div class="tip-container">
      <p class="body2--regular content-main-color">
        <img src="assets/img/info-icon.svg" />
        <ng-container *ngIf="selectedType !== 'time'; else timeInfo">
          {{
            trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.TIP_INFO')
          }}
        </ng-container>
        <ng-template #timeInfo>
          {{
            trans(
              'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.TIP_INFO_TIME'
            )
          }}
        </ng-template>
      </p>
    </div>
    <div class="button-container">
      <button class="body2--bold dialog-button discard_btn" (click)="closeDialog()">
        {{
          trans(
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.DISCARD_BUTTON'
          )
        }}
      </button>
      <button class="body2--bold dialog-button submit_btn" (click)="onSubmit()">
        {{
          trans(
            'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.SUBMIT_BUTTON'
          )
        }}
      </button>
    </div>
  </form>
</div>
