import { getDayCountFromNow } from 'src/app/presentation/shared/utilities/get-day-count-from-now.utility';
import { translateRequestRejectionReason } from 'src/app/presentation/shared/utilities/translate-request-rejection-reason.utility';
import { Mapper } from '../../../../core/base/mapper';
import { PreOrderRequestModel } from '../../../../core/domain/bulk-pre-order/pre-order-request.model';
import { RequestListingEntity } from '../request-listing-entity';
import { mapPreOrderRequestStatus } from './pre-order-status.mapper';

export class PreOrderRequestMapper extends Mapper<RequestListingEntity, PreOrderRequestModel> {
  mapFrom(request: RequestListingEntity): PreOrderRequestModel {
    const remainingDays = getDayCountFromNow(request.expiryDate);
    return {
      prodId: request.prodId,
      productName: request.productName,
      productPicture: request.productPicture,
      status: mapPreOrderRequestStatus(request.status),
      createdAt: request.createdAt,
      remainingDays,
      offerImages: request.offerImages,
      lockedWalletAmount: request.lockedWalletAmount,
      fulfilledQuantity: request.fulfilledQuantity,
      requestedQuantity: request.requestedQuantity,
      rejectionReason: request.rejectionReason
        ? translateRequestRejectionReason(request.rejectionReason)
        : '',
      penalizedWalletAmount: request.penalizedWalletAmount,
      progressPercentage: Math.round(
        Math.min((request.fulfilledQuantity / request.requestedQuantity) * 100, 100),
      ),
      downPaymentPercentageOfferedByAdmin: request.downPaymentPercentageOfferedByAdmin,
      downPaymentPercentageSuggestedByMerchant: request.downPaymentPercentageSuggestedByMerchant,
      finalDownPaymentPercentageAgreed: request.finalDownPaymentPercentageAgreed,
      expectedProductAcquisitionDays: request.expectedProductAcquisitionDays,
      expectedProductArrivalDate: request.expectedProductArrivalDate,
      notesAddedByAdmin: request.notesAddedByAdmin,
      notesAddedByMerchant: request.notesAddedByMerchant,
      moneyReleaseDates: request.moneyReleaseDates,
      startedAt: request.startedAt,
      productLockingPrice: request.productLockingPrice,
      requestId: request.requestId,
      productObjectId: request.productObjectId,
      externalProduct: request.externalProduct,
    };
  }

  mapTo(param: PreOrderRequestModel): RequestListingEntity {
    throw new Error('Method not implemented.');
  }
}
