// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
/**
 * This service class is responsible for managing current country in the project
 * P.S: it is not related to phone number country list in the login/registration process
 */
import cache from '@mongez/cache';
import { trans } from '@mongez/localization';
import { Country } from '../../domain';
import { CURRENT_COUNTRY_CACHE_KEY } from '../constants';
import { getCountryFlag } from '../utils';

const mappedCountryCodes = {
  KSA: 'SAU',
  EGY: 'EGY',
  UAE: 'ARE',
  IRQ: 'IRQ',
  Test: 'TNT',
  KWT: 'KWT',
  OMN: 'OMN',
} as const;

type CountryCode = keyof typeof mappedCountryCodes;
type CountryCodeValue = (typeof mappedCountryCodes)[CountryCode];

export class CountryService {
  /**
   * Current country
   */
  protected currentCountry?: Country;

  /**
   * Country details from country resolver package
   */
  protected _countryDetails?: any;

  /**
   * Constructor
   */
  public constructor() {
    this.currentCountry = cache.get(CURRENT_COUNTRY_CACHE_KEY);
  }

  public get countryDetails(): any {
    if (!this._countryDetails) {
      this._countryDetails = getCountryFromIsoCode3(this.code);
    }

    return this._countryDetails;
  }

  /**
   * Check if country is set
   */
  public get exists(): boolean {
    return !!this.currentCountry;
  }

  /**
   * Set default country
   * Currently it is KSA
   */
  public setDefaultCountry(): void {
    this.set({
      currencyCode: 'SAR',
      isoCode2: 'SA',
      isoCode3: 'SAU',
      vat: 15,
      name: trans('SAU'),
      flag: getCountryFlag('SAU'),
    });
  }

  /**
   * Set current country
   */
  public set(country: Country): void {
    this.currentCountry = country;
    cache.set(CURRENT_COUNTRY_CACHE_KEY, country);
  }

  /**
   * Get current country code
   * Country code is represented by iso3 code
   */
  public get code(): string {
    return this.currentCountry?.isoCode3 || '';
  }

  /**
   * Get current country code shortened
   * Country shortened code is represented by iso2 code
   */
  public get shortCode(): string {
    return this.currentCountry?.isoCode2 || '';
  }

  /**
   * Get country name
   */
  public get name(): string {
    return trans(this.code);
  }

  /**
   * Determine if current country is the given country code
   */
  public is(code: CountryCode): boolean {
    const properCode = mappedCountryCodes[code] as CountryCodeValue;
    return this.code === properCode;
  }

  public get data(): Country | undefined {
    return this.currentCountry;
  }

  /**
   * Get country flag
   */
  public get flag(): string {
    return this.currentCountry?.flag || '';
  }

  /**
   * Get country phone regex
   */
  public get phoneRegex(): string | undefined {
    return this.countryDetails?.phoneRegex;
  }

  /**
   * Get country phone number hint
   */
  public get phoneNumberHint(): string {
    return this.countryDetails?.phoneNumberHint || '';
  }

  public get currency(): string {
    return this.currentCountry?.currencyCode || '';
  }
}

export const country = new CountryService();
