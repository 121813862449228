import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { country } from '@features/country/data/services';
import { trans } from '@mongez/localization';
import { TranslateModule } from '@ngx-translate/core';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { AmountLockDialogComponent } from '../bulk-pre-order/amount-lock-dialog/amount-lock-dialog.component';

@Component({
  selector: 'app-bulk-pre-order-v3',
  templateUrl: './bulk-pre-order-v3.component.html',
  styleUrls: ['./bulk-pre-order-v3.component.scss'],
  imports: [TranslateModule, NgIf, NgClass, ReactiveFormsModule],
  standalone: true,
})
export class BulkPreOrderV3Component implements OnInit {
  agreeToPercentage = true;

  preorderForm: FormGroup;

  preOrderQuantity: number;

  productPrice: number;

  reservedAmount: number;

  currency: string;

  depositRatio = 30;

  minOrderQuantity = 50;

  constructor(
    private dialogRef: MatDialogRef<BulkPreOrderV3Component>,
    private dialog: MatDialog,
    private _siteTranslateService: SiteTranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      walletAmount: number;
      sellingPrice: number;
      merchantMargin: number;
      productId: string;
    },
  ) {}

  ngOnInit(): void {
    this.currency = trans(`currencies.${country.currency}`);

    this.productPrice = this.data.sellingPrice - this.data.merchantMargin;
    this.preorderForm = new FormGroup({
      quantity: new FormControl('', [Validators.required, Validators.min(this.minOrderQuantity)]),
      value: new FormControl(''),
      comment: new FormControl(''),
    });
  }

  get quantity(): AbstractControl {
    return this.preorderForm.get('quantity')!;
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onProceed() {
    this.dialogRef.close({ success: true });
    this.dialogRef.afterClosed().subscribe({
      next: () => {
        this.dialog.open(AmountLockDialogComponent, {
          width: '484px',
          data: {
            amount: this.reservedAmount,
            quantity: this.preOrderQuantity,
            productId: this.data.productId,
            depositRatio: this.depositRatio,
            comment: this.preorderForm.value.comment,
          },
          direction: this._siteTranslateService.currentDir,
        });
      },
    });
  }

  toggleAgreeToPercentage() {
    this.agreeToPercentage = !this.agreeToPercentage;
    if (!this.agreeToPercentage) {
      this.preorderForm.controls.value.setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.preorderForm.controls.value.clearValidators();
    }
    this.preorderForm.controls.value.updateValueAndValidity();
  }

  onUpdatePreference(): void {
    this.reservedAmount = this.preOrderQuantity
      ? Math.floor(this.preOrderQuantity * this.productPrice * (this.depositRatio / 100))
      : 0;
  }
}
