<div class="preorder" [formGroup]="preorderForm">
  <div class="preorder__title-row">
    <p class="heading2--bold content-main preorder__title-row__text">
      {{ 'PRE_ORDER_V3_DIALOG.TITLE' | translate }}
    </p>
    <div class="preorder__title-row__close" (click)="onDismiss()">
      <img src="/assets/img/close.svg" />
    </div>
  </div>
  <div class="preorder__body">
    <p class="body2--regular content-medium">
      {{ 'PRE_ORDER_V3_DIALOG.DESCRIPTION' | translate }}
    </p>
    <div class="preorder__input-row">
      <input
        class="body2--regular content-main form-control preorder__input-row__quantity"
        type="number"
        formControlName="quantity"
        placeholder="{{ 'PRE_ORDER_V3_DIALOG.QUANTITY_TO_RESERVE' | translate }}"
        [(ngModel)]="preOrderQuantity"
        (ngModelChange)="onUpdatePreference()"
      />
      <p class="caption2--medium content-medium">=</p>
      <div>
        <p class="caption2--medium content-medium">
          {{ 'PRE_ORDER_V3_DIALOG.TOTAL_ORDER_COST' | translate }}
        </p>
        <div class="preorder__calculated-sum">
          <i class="icon icon-dollar content-medium"></i>
          <p class="body2--bold content-main">
            {{ preOrderQuantity ? preOrderQuantity * productPrice : 0 }}
            {{ currency }}
          </p>
        </div>
      </div>
    </div>
    <p
      *ngIf="quantity.dirty && quantity.invalid && quantity.errors!.min"
      class="content-error caption2--regular"
    >
      {{ 'PRE_ORDER_DIALOG.MINIMUM_QUANTITY_TO_RESERVE' | translate }}: {{ minOrderQuantity }}
    </p>
    <hr />
    <p class="body2--bold content-main">{{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.TITLE' | translate }}</p>
    <p class="caption1--regular content-medium">
      {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.DESCRIPTION' | translate }}
    </p>
    <div class="preorder__offers">
      <button
        class="body2--regular"
        [ngClass]="
          agreeToPercentage ? 'preorder__offers__selected' : 'preorder__offers__unselected'
        "
        (click)="toggleAgreeToPercentage()"
      >
        {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.AGREE' | translate }}
      </button>
      <button
        class="body2--regular"
        [ngClass]="
          !agreeToPercentage ? 'preorder__offers__selected' : 'preorder__offers__unselected'
        "
        (click)="toggleAgreeToPercentage()"
      >
        {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.REQUEST_ANOTHER' | translate }}
      </button>
    </div>
    <div class="preorder__input-row" *ngIf="!agreeToPercentage">
      <input
        class="body2--regular content-main form-control preorder__input-row__value"
        type="number"
        formControlName="value"
        placeholder="{{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.VALUE' | translate }}"
        [(ngModel)]="depositRatio"
        (ngModelChange)="onUpdatePreference()"
      />
      <span class="percentage-suffix">%</span>
    </div>
    <div class="preorder__input-row">
      <input
        class="body2--regular content-main form-control preorder__input-row__value"
        type="text"
        formControlName="comment"
        placeholder="{{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.COMMENT' | translate }}"
      />
    </div>
    <div class="form-control preorder__summary">
      <img src="/assets/img/wallet.svg" alt="wallet" />
      <div>
        <p class="caption1--regular content-medium">
          {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.EXPECTED_AMOUNT' | translate }}
        </p>
        <p class="body2--bold content-main">
          {{ reservedAmount }}
          <span class="body2--medium content-main">
            {{ currency }}
          </span>
          <span class="body2--regular"
            >({{ depositRatio }}% {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.PERCENTAGE' | translate }})
          </span>
        </p>
        <div class="preorder__summary__disclaimer">
          <img src="/assets/img/info-icon.svg" alt="" />
          <p class="caption1--regular content-main">
            {{ 'PRE_ORDER_V3_DIALOG.DEPOSIT.DISCLAIMER' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="preorder__CTAs">
      <button
        class="body2--medium btn preorder__CTAs--proceed"
        type="submit"
        (click)="onProceed()"
        [disabled]="preorderForm.invalid"
      >
        {{ 'PRE_ORDER_DIALOG.CONFIRM' | translate }}
      </button>
      <button class="body2--medium btn preorder__CTAs--cancel" (click)="onDismiss()">
        {{ 'PRE_ORDER_DIALOG.CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
