export const lockedTestableProductsAPI = {
  lockedTestableProductsList: '/products/variants/locked-with-unallocated-quantities',
  merchantTestableProductRelation: (taagerId: number, productId: string): string =>
    `/products/variants/${productId}/merchant-info/${taagerId}`,
};

export const lockedTestableProductsTrackEvents = {
  VIEW_ALL: 'testing-products-view-all-clicked',
  LEARN_MORE: 'testing-products-learn-more-clicked',
  CLICK_PRODUCT_CARD: 'testing-products-product-card-clicked',
  ADD_TO_CART: 'testing-products-add-to-cart-clicked',
  ADD_TO_CART_INSIDE: 'testing-products-add-to-cart-inside-clicked',
  ORDER_NOW: 'testing-products-order-now-clicked',
  LOCK_QUANTITY: 'testing-products-lock-quantity-clicked',
};
