import { ProductLockRequestUseCaseResolver } from '@/v3/features/product-lock-request/domain/resolvers/product-lock-request-use-case.resolver';
/* eslint-disable-next-line */
import { ProductLockRequestCreateModel } from '@/v3/features/product-lock-request/data/models/index';
/* eslint-disable-next-line */
import { parseError } from '@/v3/presentation/shared/error/index';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { LoaderComponent } from 'app/presentation/shared/components/loader/loader.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-lock-extension-popup',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule, LoaderComponent],
  templateUrl: './product-lock-extension-popup.component.html',
  styleUrls: ['./product-lock-extension-popup.component.scss'],
})
export class ProductLockExtensionPopupComponent extends LocalizedComponent {
  constructor(
    public _dialogRef: MatDialogRef<ProductLockExtensionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { sku: string; productName: string },
    public toastrService: ToastrService,
  ) {
    super();
  }

  isLoading = false;

  selectedType = 'EXTENSION_TIME_QUANTITY';

  requestedQuantity: number;

  public selectionTypes: { name: string; value: string; isActive: boolean }[] = [
    {
      name: this.trans(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.TIME_QUANTITY',
      ),
      value: 'EXTENSION_TIME_QUANTITY',
      isActive: true,
    },
    {
      name: this.trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.TIME'),
      value: 'EXTENSION_TIME',
      isActive: false,
    },
  ];

  public onTypeSelected(typeValue: string): void {
    this.selectedType = typeValue;
    if (typeValue === 'EXTENSION_TIME') {
      this.selectionTypes[0].isActive = false;
      this.selectionTypes[1].isActive = true;
    } else {
      this.selectionTypes[0].isActive = true;
      this.selectionTypes[1].isActive = false;
    }
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public onSubmit(): void {
    this.isLoading = true;
    const data: ProductLockRequestCreateModel = {
      requestReason: this.selectedType,
      requestedQuantity: this.requestedQuantity,
      sku: this.dialogData.sku,
      requestType: 'LOCK_EXTENSION',
    };
    ProductLockRequestUseCaseResolver.productLockRequestCreate(data)
      .then(() => {
        this.isLoading = false;
        this.toastrService.success(
          'PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.PRODUCT_LOCK_EXTENSION_POPUP.SUCCESS_MESSAGE',
        );
        this.closeDialog();
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastrService.error(parseError(error));
      });
  }
}
