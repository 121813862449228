<ng-container *ngIf="product.merchantInfo.orderTestingStarted; else remainingMerchantsContainer">
  <div class="started-testing-container">
    <div class="info-container">
      <img src="assets/img/sold-quantity-box.svg" alt="box outline" />
      <p class="caption3--medium">
        {{
          trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.SOLD_OUT_QUANTITY', {
            pcsSold,
            totalPcs: product.orderTestingInfo.maxQuantity
          })
        }}
      </p>
    </div>

    <app-progress-bar [percentage]="testingProgressPercentage"></app-progress-bar>

    <ng-container *ngIf="pcsToEnableLocking > 0; else qualifiedQuantityTemplate">
      <p class="help-text caption3--medium">
        {{
          trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.REMAINING_QUANTITY', {
            remainingPcs: pcsToEnableLocking
          })
        }}
      </p>
    </ng-container>

    <ng-template #qualifiedQuantityTemplate>
      <p class="help-text caption3--medium">
        {{ trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.QUALIFIED_QUANTITY') }}
      </p>
    </ng-template>
  </div>
</ng-container>

<ng-template #remainingMerchantsContainer>
  <div class="remaining-merchants-container">
    <div class="info-container">
      <div class="info-container__merchants">
        <ng-lottie [options]="lottieOptions" height="20px" width="20px"></ng-lottie>
        <div
          class="info-container__merchants__content"
          [ngClass]="{ 'animate-content': 100 - merchantsActivePercentage < 25 }"
        >
          <p class="caption2--medium">
            <span>{{ product.orderTestingInfo.merchantsLeft }}</span>
            {{ trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.SPOTS_LEFT') }}
          </p>
          <p class="caption2--medium limited_text">
            {{ trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.LIMITED_SLOTS') }}
          </p>
        </div>
      </div>
      <div class="info-container__pieces">
        <img src="assets/img/pieces-box.svg" alt="box outline" />
        <p class="caption3--medium">
          <span>{{ product.orderTestingInfo.maxQuantity }}</span>
          {{ trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.PCS_PER_MERCHANT') }}
        </p>
      </div>
    </div>

    <app-progress-bar
      [percentage]="merchantsActivePercentage"
      [backgroundColor]="'#FEDCC8'"
      [progressColor]="'linear-gradient(90deg, #EEAF32 0%, #B0001C 100%)'"
    ></app-progress-bar>

    <div>
      <app-on-hover-tooltip
        [targetTemplate]="ineligibleInfoTemplate"
        [heading]="trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.CLAIM_QUANTITY_TIP_TITLE')"
        [content]="trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.CLAIM_QUANTITY_TIP_SUBTITLE')"
        [arrowDirection]="'bottom'"
        [tooltipStyles]="tooltipStyles"
      ></app-on-hover-tooltip>
      <ng-template #ineligibleInfoTemplate>
        <div class="help-text-container">
          <p class="caption3--medium">
            {{ trans('PRODUCTS_PAGE.PRODUCT_CARD_TESTING_INFO.FIRST_ORDER') }}
          </p>
          <img src="assets/img/info-tooltip.svg" alt="info" [ngClass]="{ flip: isRTL }" />
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
