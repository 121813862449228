import { groupedTranslations } from '@mongez/localization';

groupedTranslations('currencies', {
  OMR: {
    en: 'OMR',
    ar: 'ريال عماني',
  },
  SAR: {
    en: 'SAR',
    ar: 'ريال سعودي',
  },
  AED: {
    en: 'AED',
    ar: 'درهم إماراتي',
  },
  IQD: {
    en: 'IQD',
    ar: 'دينار عراقي',
  },
  KWD: {
    en: 'KWD',
    ar: 'دينار كويتي',
  },
  EGP: {
    en: 'EGP',
    ar: 'جنيه مصري',
  },
  CTM: {
    en: 'CTM',
    ar: 'CTM',
  },
});
