export const INCENTIVE_PROGRAM_V3 = 'WEB_INCENTIVE_PROGRAM_V3';
export const CAPPED_INCENTIVE_PROGRAM_FLAG = 'WEB_CAPPED_INCENTIVE_PROGRAM_FLAG';
export const DELIVERY_RATE_INCENTIVE_FLAG = 'WEB_DELVIERY_RATE_INCENTIVE_FLAG';
export const INCENTIVE_PROGRAM_NAME = 'WEB_INCENTIVE_PROGRAM_NAME';
export const INCENTIVE_PROGRAM_START_DATE = 'WEB_INCENTIVE_PROGRAM_START_DATE';
export const INCENTIVE_PROGRAM_END_DATE = 'WEB_INCENTIVE_PROGRAM_END_DATE';

export const KSA_INCENTIVE_PROGRAM = 'WEB_KSA_INCENTIVE_PROGRAM';

export const CASH_INCENTIVE_PAYMENT_METHOD_HELPER_TEXT_FEATURE =
  'WEB_CASH_INCENTIVE_PAYMENT_METHOD_HELPER_TEXT_FEATURE';

export const KSA_ONBOARDING_FLAG = 'WEB_KSA_ONBOARDING_FLAG';

export const MERCHANT_ONBOARDING_FEATURE = 'WEB_MERCHANT_ONBOARDING_FEATURE';

export const ONBOARDING_V2 = 'WEB_ONBOARDING_V2';

export const BULK_PRE_ORDERS_EXPERIMENT = 'WEB_BULK_PRE_ORDERS_EXPERIMENT';

export const DISPLAY_REVAMPED_LANDING = 'WEB_DISPLAY_REVAMPED_LANDING';

export const ORDERS_PHONE_VALIDATIONS_FLAG = 'WEB_ORDERS_PHONE_VALIDATIONS_FLAG';

export const USER_ACQUISITION_CHANNELS = 'WEB_USER_ACQUISITION_CHANNELS';

export const INTRODUCE_PRODUCT_FEATURE = 'WEB_INTRODUCE_PRODUCT_FEATURE';

export const SEARCH_OPTIMIZATION_FEATURE = 'WEB_SEARCH_OPTIMIZATION_FEATURE';

export const FLASH_SALE_TITLE = 'WEB_SALE_TITLE';

export const LOCKED_CAROUSEL = 'WEB_LOCKED_CAROUSEL';

export const UPSELLABLE_PRODUCTS_FLAG = 'WEB_UPSELLABLE_PRODUCTS_FLAG';

export const CUSTOMER_IO_FEATURE = 'WEB_CUSTOMER_IO_FEATURE';

/**
 * @deprecate
 */
export const PRE_ORDER_V2 = 'WEB_PRE_ORDER_V2';
export const PRODUCT_AVAILABLE_IN_MIN_DAYS = 'WEB_PRODUCT_AVAILABLE_IN_MIN_DAYS';
export const PRODUCT_AVAILABLE_IN_MAX_DAYS = 'WEB_PRODUCT_AVAILABLE_IN_MAX_DAYS';

/**
 * @deprecate
 */
export const DISCOUNTED_AS_SECOND_PRODUCT = 'WEB_DISCOUNTED_AS_SECOND_PRODUCT';

export const STOCK_AVAILABILITY_FEATURE = 'WEB_STOCK_AVAILABILITY_FEATURE';

export const ORDER_SUMMARY_FEATURE = 'WEB_ORDER_SUMMARY_FEATURE';

export const HIDE_CART_CHECKBOX_FLAG = 'WEB_HIDE_CART_CHECKBOX_FLAG';

export const IS_SIGNUP_RECAPTCHA_ENABLED = 'WEB_IS_SIGNUP_RECAPTCHA_ENABLED';

export const RESIZE_IMAGES = 'WEB_RESIZE_IMAGES';
export const ALLOWED_IDS_FOR_RESIZE_IMAGES = 'WEB_ALLOWED_IDS_FOR_RESIZE_IMAGES';

export const USE_NEW_GUARDS = 'WEB_USE_NEW_GUARDS';

export const PRICING_RANGE_ATTRIBUTE = 'WEB_PRICING_RANGE_ATTRIBUTE';

export const REQUEST_PRODUCT_MENU_FLAG = 'WEB_REQUEST_PRODUCT_MENU_FLAG';

export const SKU_ANALYTICS = 'WEB_SKU_ANALYTICS';

export const TRANSLATION_FLAG = 'WEB_TRANSLATION_FLAG';

export const REDIRECT_USER_TO_MOBILE_APPS = 'WEB_REDIRECT_USER_TO_MOBILE_APPS';

export const PRICE_RANGE_ATTRIBUTE = 'WEB_PRICE_RANGE_ATTRIBUTE';

export const ALLOW_KUWAIT_PHONE_NUMBERS = 'WEB_ALLOW_KUWAIT_PHONE_NUMBERS';

export const OPT_IN_FLAG = 'WEB_OPT_IN_FLAG';

export const SMART_LOOK_RECORDING_ENABLED = 'WEB_SMART_LOOK_RECORDING_ENABLED';
export const SMART_LOOK_START_HOUR = 'WEB_SMART_LOOK_START_HOUR';
export const SMART_LOOK_END_HOUR = 'WEB_SMART_LOOK_END_HOUR';

/**
 * @deprecate
 */
export const WEB_STORES = 'IS_STORES_ENABLED';
export const ZID_INTEGRATION = 'IS_ZID_ENABLED';
export const YOUCAN_STORE = 'IS_YOUCAN_ENABLED';
export const WOOCOMMERCE_STORE = 'IS_WOOCOMMERCE_ENABLED';

export const IS_DUKAN_ENABLED = 'IS_DUKAN_ENABLED';
export const IS_RICH_TEXT_EDITOR_ENABLED = 'IS_RICH_TEXT_EDITOR_ENABLED';
export const WEB_PRODUCT_DETAILS_LIBRARY_REMOVAL = 'WEB_PRODUCT_DETAILS_LIBRARY_REMOVAL';

export const WEB_DYNAMIC_INCENTIVE_PROGRAM = 'IS_DYNAMIC_INCENTIVE_ENABLED';

export const WALLET_TRANSACTIONS_HISTORY = 'WEB_WALLET_TRANSACTIONS_HISTORY';

export const QUANTITY_DISCOUNT = 'WEB_QUANTITY_DISCOUNT';

export const WEB_DISCOUNT_PRODUCT_CARD = 'WEB_DISCOUNT_PRODUCT_CARD';

export const WEB_WALLET_TRANSACTION_SURVEY = 'WEB_WALLET_TRANSACTION_SURVEY';

export const SHOW_CUSTOMER_EFFORT_SUPPORT = 'WEB_CUSTOMER_EFFORT_SUPPORT';

export const MERCHANT_INSIGHTS = 'IS_MERCHANT_INSIGHTS_ENABLED';

export const SHOW_MERCHANT_LEADS = 'WEB_MERCHANT_LEADS';

export const CPA_CALCULATOR = 'IS_CPA_CALCULATOR_ENABLED';

export const STOCK_DISTRIBUTION = 'WEB_STOCK_DISTRIBUTION';

export const SHOW_PRODUCT_KEYWORDS = 'IS_PRODUCT_KEYWORDS_ENABLED';

export const FRESH_DESK_CHAT = 'IS_FRESH_DESK_ENABLED';

export const DISCOVERABILITY_FUNNEL = 'IS_DISCOVERABILITY_ENABLED';

export const WEB_TELE_SALES_ORDERS = 'WEB_TELE_SALES_ORDERS';

export const UP_SELLING_FEEDBACK = 'IS_UP_SELLING_FEEDBACK_ENABLED';

export const ORDER_PREFERENCE = 'IS_ORDER_PREFERENCE_ENABLED';

export const VAT_ON_DISCOUNTED_PRICE = 'VAT_ON_DISCOUNTED_PRICE';

export const PREORDER_V3 = 'IS_PREORDER_V3_ENABLED';

export const WEB_ORDERS_REVAMP = 'WEB_ORDERS_REVAMP';

export const FAILED_ORDERS = 'IS_FAILED_ORDERS_ENABLED';

export const CREATIVES_FEATURE = 'IS_CREATIVES_FEATURE_ENABLED';

export const PRE_ORDER_UI_REVAMP = 'IS_PRE_ORDER_UI_REVAMP_ENABLED';

export const TAMARA_PREFERENCE = 'WEB_TAMARA_PREFERENCE';

export const ENHANCED_WALLET_SECURITY = 'ENHANCED_WALLET_SECURITY';

export const TALLY_COMPLAIN_SUGGEST = 'TALLY_COMPLAIN_SUGGEST';

export const API_KEYS_INTEGRATION = 'IS_API_KEYS_INTEGRATION_ENABLED';

export const LOYALTY_PROGRAM = 'IS_LOYALTY_PROGRAM_ENABLED';

export const REFERRAL_PROGRAM = 'IS_REFERRAL_PROGRAM_ENABLED';

export const WEB_CUSTOM_CHECKOUT = 'WEB_CUSTOM_CHECKOUT';

export const WEB_SIGNUP_V2 = 'WEB_SIGNUP_V2';

export const KYC_FEATURE = 'KYC_ENABLED';

export const EMAIL_OTP_WITHDRAW = 'EMAIL_OTP_WITHDRAW';

export const WEB_MARKETPLACE_V1 = 'WEB_MARKETPLACE_V1';

export const WEB_CUSTOM_LANDING_DESC = 'WEB_CUSTOM_LANDING_DESC';

export const WEB_CUSTOM_LANDING_FORM_SWITCHER = 'WEB_CUSTOM_LANDING_FORM_SWITCHER';

export const SURVEY_BANNER_ENABLED = 'SURVEY_BANNER_ENABLED';

export const WEB_MARKETPLACE_V2 = 'WEB_MARKETPLACE_V2';

export const MARKETPLACE_USER_FEATURE = 'market-place-notifications-lock-updates';

export const REVAMPED_STORES = 'REVAMPED_STORES_ENABLED';

export const NEW_INCENTIVE_UI_FEATURE_FLAG = 'NEW_DYNAMIC_INCENTIVE_UI_ENABLED';

export const WEB_MARKETPLACE_V3 = 'WEB_MARKETPLACE_V3';

export const WEB_MARKETPLACE_V3_2 = 'WEB_MARKETPLACE_V3_2';

export const WEB_PRICE_GOVERNANCE = 'WEB_PRICE_GOVERNANCE';

export const WEB_ADDRESS_AUTOCONFIRMATION = 'WEB_ADDRESS_AUTOCONFIRMATION';

export const MARKETPLACE_V3_2_USER_FEATURE = 'marketplace-lock-unallocated-quantities';

export const WEB_MARKETPLACE_V3_3 = 'WEB_MARKETPLACE_V3_3';

export const WEB_MARKETPLACE_V3_4 = 'WEB_MARKETPLACE_V3_4';
