<div class="tooltip-wrapper">
  <ng-container *ngTemplateOutlet="targetTemplate"> </ng-container>
  <div class="tooltip" [ngStyle]="tooltipStyles" [ngClass]="{ 'max-content': fullWidth }">
    <div class="arrow" [ngClass]="arrowDirection"></div>
    <div>
      <p *ngIf="heading" class="caption1--bold white-color">{{ heading }}</p>
      <p class="caption2--regular white-color" [ngClass]="{ 'gray-color': heading }">
        {{ content }}
      </p>
    </div>
  </div>
</div>
