<div class="pre-order-request-card">
  <div class="pre-order-request-card__details">
    <img
      class="clickable"
      (click)="onProductCardClick(request.productObjectId)"
      src="{{ request.productPicture }}"
    />
    <div class="card-data">
      <div class="request-card-header">
        <div class="product-details">
          <p
            class="content-main-color body1--bold clickable"
            (click)="onProductCardClick(request.productObjectId)"
          >
            {{ request.productName }}
          </p>
          <p class="content-medium-color body2--medium">{{ request.prodId }}</p>
        </div>
        <p class="request-status body2--medium">
          {{ mappedRequestStatus | translate }}
        </p>
      </div>
      <div class="request-card-body">
        <div class="request-card-body__content">
          <p class="content-medium-color caption1--regular">
            {{ request.createdAt | date : dateFormat }}
          </p>
          <p class="content-main-color body2--bold">
            {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.OFFERED' | translate }}
          </p>
          <div class="same-row-container">
            <div class="request-card-body__content__offer">
              <p class="content-medium-color caption2--medium">
                {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.DURATION' | translate }}
              </p>
              <p class="content-main-color body2--bold">
                {{ request.expectedProductAcquisitionDays
                }}{{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.DAY' | translate }}
              </p>
              <p class="caption2--medium info-color">
                {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.AFTER_ACCEPTANCE' | translate }}
              </p>
            </div>
            <div class="request-card-body__content__offer">
              <p class="content-medium-color caption2--medium">
                {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.BUDGET' | translate }}
              </p>
              <p class="content-main-color body2--bold">
                {{ budgetToBeReservedFromWallet
                }}{{ currency | currencyTranslate | translate | currencyShortName }}
              </p>
              <p class="caption2--medium info-color">
                {{ request.downPaymentPercentageOfferedByAdmin }}%{{
                  'PRE_ORDER_PAGE.DOWN_PAYMENT.FROM_AMOUNT' | translate
                }}
              </p>
            </div>
            <div class="request-card-body__content__offer">
              <p class="content-medium-color caption2--medium">
                {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.AMOUNT' | translate }}
              </p>
              <p class="content-main-color body2--bold">
                {{ request.requestedQuantity }}
              </p>
            </div>
          </div>
          <p class="offer-text body2--medium" *ngIf="request.notesAddedByAdmin">
            <img src="/assets/img/comment.svg" />
            {{ request.notesAddedByAdmin }}
          </p>
          <div *ngIf="request.offerImages?.length > 0">
            <p class="content-medium-color caption2--medium">
              {{ 'PRE_ORDER_PAGE.OFFER_IMAGES' | translate }}
            </p>
            <div class="offer-images">
              <img
                *ngFor="let image of request.offerImages"
                [src]="image"
                (click)="onImageClick(image)"
                alt="offer-image"
                class="offer-image"
              />
            </div>
          </div>
        </div>
        <div class="request-card-body__actions">
          <button
            class="request-action-btn request-action-btn__accept caption1--regular"
            (click)="
              onAcceptOffer(
                request.requestedQuantity,
                request.downPaymentPercentageOfferedByAdmin,
                request.requestId,
                request.productLockingPrice
              )
            "
          >
            {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.ACCEPT' | translate }}
          </button>
          <button
            class="request-action-btn request-action-btn__reject caption1--regular"
            (click)="onRejectOffer(request.requestId)"
          >
            {{ 'PRE_ORDER_PAGE.DOWN_PAYMENT.REJECT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
