import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PreOrderRequestModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { AcceptPreOrderOfferDialogComponent } from '../../products/bulk-pre-order-v3/offer/accept-pre-order-offer-dialog/accept-pre-order-offer-dialog.component';
import { RejectPreOrderOfferDialogComponent } from '../../products/bulk-pre-order-v3/offer/reject-pre-order-offer-dialog/reject-pre-order-offer-dialog.component';
import { dateFormat, PRODUCT_DETAILS } from '../../shared/constants';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { MultitenancyService } from '../../shared/services/multitenancy.service';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { LocalizedPreOrderStatusesMapper } from '../pre-order-request-statuses';

@Component({
  selector: 'app-pre-order-incoming-request-card',
  standalone: true,
  imports: [TranslateModule, DatePipe, CurrencyTranslatePipe, CurrencyShortNamePipe, NgIf, NgFor],
  templateUrl: './pre-order-incoming-request-card.component.html',
  styleUrls: ['./pre-order-incoming-request-card.component.scss'],
})
export class PreOrderIncomingRequestCardComponent implements OnInit {
  @Input() request: PreOrderRequestModel;

  @Output() statusUpdated = new EventEmitter<void>();

  public dateFormat = dateFormat;

  public currency: string;

  public budgetToBeReservedFromWallet: number;

  public productDetailsUrls = PRODUCT_DETAILS;

  public mappedRequestStatus: string;

  constructor(
    private _multitenancyService: MultitenancyService,
    private _siteTranslateService: SiteTranslateService,
    private _dialog: MatDialog,
    private _router: Router,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {}

  ngOnInit(): void {
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this.budgetToBeReservedFromWallet = Math.floor(
      this.request.requestedQuantity *
        this.request.productLockingPrice *
        (this.request.downPaymentPercentageOfferedByAdmin / 100),
    );

    this.mappedRequestStatus = LocalizedPreOrderStatusesMapper(this.request.status);
  }

  onRejectOffer(requestId: string): void {
    const dialogRef = this._dialog.open(RejectPreOrderOfferDialogComponent, {
      width: '490px',
      direction: this._siteTranslateService.currentDir,
      data: {
        requestId,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.statusUpdated.emit();
      },
    });
  }

  onImageClick(image: string): void {
    // Open it in a new tab
    window.open(image, '_blank');
  }

  onAcceptOffer(
    quantity: number,
    depositRatio: number,
    requestId: string,
    productLockingPrice: number,
  ): void {
    const dialogRef = this._dialog.open(AcceptPreOrderOfferDialogComponent, {
      width: '490px',
      direction: this._siteTranslateService.currentDir,
      data: {
        quantity,
        depositRatio,
        requestId,
        productLockingPrice,
        currency: this.currency,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.statusUpdated.emit();
      },
    });
  }

  onProductCardClick(productObjectId: string): void {
    this._router.navigate([this.productDetailsUrls, productObjectId]);
    this._logMixpanelEventUseCase.execute({
      eventName: 'pre_order_card_product_clicked',
    });
  }
}
