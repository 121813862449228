import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { hardNavigateMiddleware } from '@presentation/shared/router';
import { MERCHANT_INSIGHTS_URL } from 'app/presentation/shared/constants';
import { MerchantInsightsComponent } from './merchant-insights.component';

@NgModule({
  imports: [MerchantInsightsComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: MerchantInsightsComponent,
        canActivate: [hardNavigateMiddleware(MERCHANT_INSIGHTS_URL)],
      },
    ]),
  ],
})
export class MerchantInsightsModule {}
