import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { GetDukanShopUseCase } from 'src/app/core/usecases/dukan-shop/get-dukan.usecase';
import { STORES_URL } from '../../../constants';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';

/**
 * as a user, I can only access Dukan Page if:
 *
 * - I am logged in.
 * - I am fully signed up.
 * - I have met other internal rules for me to view the Dukan page, which are specific to
 * the Dukan
 */
export const editDukanResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const getDukanShopUseCase = inject(GetDukanShopUseCase);
  return new Observable((subscriber) => {
    combineLatest({
      hasDukan: getDukanShopUseCase.execute(),
    }).subscribe({
      next: () => {
        subscriber.next(true);
      },
      error: () => {
        subscriber.next(stringToUrlTree(STORES_URL, router));
      },
    });
  });
};
