import { LockedTestableProductData } from '../../domain/types/data.types';
import { LockedTestableProductModel } from '../models';

export function mapLockedTestableProductsData(
  LockedTestableProductsData: LockedTestableProductModel,
): LockedTestableProductData {
  return {
    _id: LockedTestableProductsData.legacyId,
    prodID: LockedTestableProductsData.sku,
    productName: LockedTestableProductsData.name.ar,
    productPrice: LockedTestableProductsData.productPrice,
    productProfit: LockedTestableProductsData.productProfit,
    productPicture: LockedTestableProductsData.productPicture,
    productAvailability: LockedTestableProductsData.productAvailability,
    sale: LockedTestableProductsData.sale,
    discountedAsSecondInConfirmation: LockedTestableProductsData.discountedAsSecondInConfirmation,
    discountedAsSecondInPlacement: LockedTestableProductsData.discountedAsSecondInConfirmation,
    merchantInfo: LockedTestableProductsData.merchantInfo,
    orderTestingInfo: LockedTestableProductsData.orderTestingInfo,
    // old property will be removed when fully deprecated everywhere
    isAvailableToSeller: true,
  } as LockedTestableProductData;
}
