<div class="product-card">
  <section class="product-card__info">
    <section class="product-card__info__details">
      <img
        loading="lazy"
        appHoverStyle
        class="product-card__info__details__image"
        [src]="product.media[0]"
        [alt]="product.name.en"
      />
      <section class="product-card__info__details__text">
        <p class="product-name caption1--medium">{{ product.name.ar }}</p>
        <section class="price-profit">
          <section class="price-profit--pair">
            <span class="product-price--title caption1--medium">
              {{ 'STORES.DUKAN.DUKAN_PROD_CARD.PRICE' | translate }}
              :</span
            >
            <span
              *ngIf="product.pricingOptions.length === 1; else showMorePricingOptions"
              class="product-price--value body2--bold"
              >{{ product.pricingOptions[0].totalPrice }}
              {{ currency | currencyTranslate | translate | currencyShortName }}</span
            >
            <ng-template #showMorePricingOptions>
              <span class="product-price--value body2--bold">{{
                'STORES.DUKAN.DUKAN_PROD_CARD.DIFFERENT_PRICE_OPTIONS' | translate
              }}</span>
            </ng-template>
          </section>
        </section>
        <section class="price-profit">
          <section class="price-profit--pair">
            <span class="product-price--title caption1--medium">
              {{ 'STORES.DUKAN.DUKAN_PROD_CARD.SKU' | translate }}
              :</span
            >
            <span class="product-price--value body2--bold">{{ product.sku }}</span>
          </section>
        </section>
      </section>
    </section>
    <section class="product-card__info__cta">
      <app-toggle-product-added [product]="product"></app-toggle-product-added>
      <ng-template #editButtonTemplate>
        <a [routerLink]="[appURLs.STORES_URL, appURLs.EDIT_DUKAN_PRODUCT_URL, productSku]">
          <i class="cta-button product-card__info__cta__edit icon icon-edit-pen"></i>
        </a>
      </ng-template>
      <ng-template #viewButtonTemplate>
        <a [href]="sanitizedProductUrl" target="_blank">
          <i class="cta-button product-card__info__cta__view icon icon-eye"></i>
        </a>
      </ng-template>
      <ng-template #deleteButtonTemplate>
        <button class="delete-btn" (click)="onDeleteProductClicked()">
          <i class="cta-button product-card__info__cta__delete icon icon-delete"></i>
        </button>
      </ng-template>
      <app-on-hover-tooltip
        [targetTemplate]="editButtonTemplate"
        [content]="'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.EDIT_TOOLTIP' | translate"
        [tooltipStyles]="{ backgroundColor: '#0f1933' }"
        [arrowDirection]="'bottom'"
      ></app-on-hover-tooltip>
      <app-on-hover-tooltip
        [targetTemplate]="viewButtonTemplate"
        [content]="'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.VIEW_TOOLTIP' | translate"
        [tooltipStyles]="{ backgroundColor: '#0f1933' }"
        [arrowDirection]="'bottom'"
      ></app-on-hover-tooltip>
      <app-on-hover-tooltip
        [targetTemplate]="deleteButtonTemplate"
        [content]="'STORES.DUKAN.DUKAN_EDIT_YOUR_STORE.DELETE_TOOLTIP' | translate"
        [tooltipStyles]="{ backgroundColor: '#0f1933' }"
        [arrowDirection]="'bottom'"
      ></app-on-hover-tooltip>
    </section>
  </section>
</div>
