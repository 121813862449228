import { inject } from '@angular/core';
import { NotificationItemModel } from 'app/core/domain/user-notification.model';
import { GetUserNotificationsUseCase } from 'app/core/usecases/user-notification/get-user-notifications.usecase';
import { SetNotificationUseCase } from 'app/core/usecases/user-notification/set-notification.usecase';
import { SetUserNotificationsUseCase } from 'app/core/usecases/user-notification/set-user-notifications.usecase';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import { PRODUCT_DETAILS } from 'app/presentation/shared/constants';
import { interval, startWith, switchMap } from 'rxjs';
/* eslint-disable */
import { user } from '@/v3/features/user/data/services/user';
import { navigateTo } from '@/v3/presentation/shared/router';
import { mixPanelEvent } from '@/v3/features/activities/data/mixpanel/index';
import { 
  PRODUCT_LOCK_CREATED, 
  PRODUCT_LOCK_QUANTITY_EXPIRING, 
  PRODUCT_LOCK_REJECTED, 
  PRODUCT_LOCK_REMOVED, 
  PRODUCT_LOCK_TIME_EXPIRING, 
  PRODUCT_LOCK_UPDATED } from 'app/presentation/shared/constants/user-notification';
import { parseError } from '@/v3/presentation/shared/error';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { WEB_MARKETPLACE_V3_4 } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { country } from '@/v3/features/country/data';
/* eslint-enable */

export type NotificationsDropdownViewState = {
  notifications: NotificationItemModel[];
  selectedNotificationId: string;
  totalNotificationCount: number;
};

export type NotificationsDropdownViewEvent =
  | { type: 'Init' }
  | { type: 'ClickNotifications' }
  | { type: 'ClickSpecificNotification'; notification: NotificationItemModel }
  | { type: 'ClickExtendQuantity'; sku: string }
  | { type: 'ClickProductProgress'; productId: string; redirectType: string };

export type NotificationsDropdownSideEffect = { type: 'init' };

export class NotificationsDropdownPresenter extends BasePresenter<
  NotificationsDropdownViewState,
  NotificationsDropdownViewEvent,
  NotificationsDropdownSideEffect
> {
  protected defaultViewState(): NotificationsDropdownViewState {
    return {
      notifications: [],
      selectedNotificationId: '',
      totalNotificationCount: 0,
    };
  }

  shouldShowMarketplaceV3_4 = false;

  private _getUserNotificationsUseCase = inject(GetUserNotificationsUseCase);

  private _setUserNotificationsUseCase = inject(SetUserNotificationsUseCase);

  private _setNotificationUseCase = inject(SetNotificationUseCase);

  private _getFeatureAttributeUseCase = inject(GetFeatureAttributeUsecase);

  protected onViewEvent(event: NotificationsDropdownViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.isMarketplaceV3_4Enabled();
        this.getNotifications();
        break;
      }
      case 'ClickNotifications': {
        mixPanelEvent('notification_open');
        if (this.viewState.totalNotificationCount > 0) {
          this.readAllUserNotifications();
        }
        break;
      }
      case 'ClickSpecificNotification': {
        this.updateViewState({
          ...this.viewState,
          selectedNotificationId: event.notification.id,
        });
        if (!event.notification.isInteracted) {
          this.readNotification();
        }
        break;
      }
      case 'ClickExtendQuantity': {
        mixPanelEvent('notification_product_extend_form');
        this.openQuantityExtendForm(event.sku);
        break;
      }
      case 'ClickProductProgress': {
        mixPanelEvent('notification_product_progress_view');
        this.redirectToProduct(event.productId, event.redirectType);
        break;
      }
    }
  }

  private isMarketplaceV3_4Enabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_4).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_4 = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  public getNotifications(): void {
    const validTypes = [
      PRODUCT_LOCK_QUANTITY_EXPIRING,
      PRODUCT_LOCK_TIME_EXPIRING,
      PRODUCT_LOCK_REMOVED,
      PRODUCT_LOCK_CREATED,
      PRODUCT_LOCK_UPDATED,
      PRODUCT_LOCK_REJECTED,
    ];
    interval(3 * 60 * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this._getUserNotificationsUseCase.execute()),
      )
      .subscribe({
        next: (res) => {
          this.viewState.notifications = res.filter((notification) =>
            validTypes.includes(notification.content.type),
          );
          this.viewState.totalNotificationCount = this.countUnreadNotifications();
        },
        error: (err) => {
          parseError(err);
        },
      });
  }

  public countUnreadNotifications(): number {
    if (!this.viewState.notifications || !Array.isArray(this.viewState.notifications)) {
      return 0;
    }
    return this.viewState.notifications.filter((notification) => !notification.isSeen).length;
  }

  public readAllUserNotifications(): void {
    const params = {
      isSeen: true,
      isInteracted: false,
    };
    this._setUserNotificationsUseCase.execute(params).subscribe({
      next: (res) => {
        this.getNotifications();
      },
      error: (err) => {
        parseError(err);
      },
    });
  }

  public readNotification(): void {
    const params = {
      notificationId: this.viewState.selectedNotificationId,
      data: {
        isSeen: true,
        isInteracted: true,
      },
    };
    this._setNotificationUseCase.execute(params).subscribe({
      next: (res) => {
        this.getNotifications();
      },
      error: (err) => {
        parseError(err);
      },
    });
  }

  public openQuantityExtendForm(sku: string): void {
    window.open(`https://tally.so/r/mDeo2l?productId=${sku}&merchant_id=${user.id}`, '_blank');
  }

  public redirectToProduct(productId: string, redirectType: string): void {
    const queryParam = this.shouldShowMarketplaceV3_4
      ? { lockExtension: true }
      : { showMarketplace: true };
    navigateTo(`${PRODUCT_DETAILS}/${productId}`, redirectType === 'viewLock' ? queryParam : {});
  }
}
