<ng-container *ngIf="viewState.marketplaceV3_3Enabled; else oldTestProductsHeader">
  <div class="new-test-products-header">
    <div>
      <p class="caption2--medium" *ngIf="viewState.merchantTierData.label.ar">
        {{
          trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_SUBTITLE', {
            tier: localizedValue(viewState.merchantTierData?.label)
          })
        }}
      </p>
      <div class="new-test-products-header__tier">
        <img
          src="assets/img/test-tier-rectangle.svg"
          alt="testing locked products background"
          class="new-test-products-header__tier_bg"
          [ngClass]="{ 'flip-bg': isRTL }"
        />
        <img
          [src]="viewState.merchantTierData?.icon"
          alt="merchant tier"
          class="new-test-products-header__tier_icon"
        />
        <p class="new-test-products-header__tier_title body1--bold">
          {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_TITLE') }}
        </p>
        <p class="new-test-products-header__tier_title body1--bold">
          {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_TITLE') }}
        </p>
      </div>
      <p class="new-test-products-header__content_title body1--bold">
        {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_CONTENT_TITLE') }}
      </p>
      <p class="new-test-products-header__content_sub-title caption1--medium">
        {{
          trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_CONTENT_SUBTITLE')
        }}
      </p>
    </div>

    <div class="new-test-products-header__actions">
      <!-- UNCOMMENT WHEN VIDEO LINK IS READY -->
      <!-- <a
        href="/"
        target="_blank"
        class="new-test-products-header__actions__learn-more"
        (click)="clickTestingProductsLearnMore()"
      >
        <img src="/assets/img/video-play.svg" alt="go to learn more video" />
        <span>
          {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_LEARN_MORE') }}
        </span>
      </a> -->

      <a
        class="new-test-products-header__actions_view-all body2--bold"
        (click)="goToProductsPage()"
      >
        {{ trans('HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL') }}
      </a>
    </div>
  </div>
</ng-container>

<ng-template #oldTestProductsHeader>
  <div class="test-products-header">
    <div class="test-products-header__tier-container">
      <p class="caption2--medium" *ngIf="viewState.merchantTierData.label.ar">
        {{
          trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_SUBTITLE', {
            tier: localizedValue(viewState.merchantTierData?.label)
          })
        }}
      </p>
      <div class="test-products-header__tier">
        <img
          src="assets/img/test-tier-rectangle.svg"
          alt="testing locked products background"
          class="test-products-header__tier_bg"
          [ngClass]="{ 'flip-bg': isRTL }"
        />
        <img
          [src]="viewState.merchantTierData?.icon"
          alt="merchant tier"
          class="test-products-header__tier_icon"
        />
        <p class="test-products-header__tier_title body1--bold">
          {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_TITLE') }}
        </p>
      </div>
    </div>

    <p class="test-products-header_view-all body2--bold" (click)="goToProductsPage()">
      {{ trans('HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL') }}
    </p>
  </div>
</ng-template>
