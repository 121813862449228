import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LockedTestableProductData } from '@features/locked-testable-products/domain';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { ProgressBarComponent } from 'app/presentation/shared/components/progress-bar/progress-bar.component';
import { LottieComponent } from 'ngx-lottie';
import { OnHoverTooltipComponent } from '../../../../shared/components/on-hover-tooltip/on-hover-tooltip.component';

@Component({
  selector: 'app-locked-testable-products-info',
  standalone: true,
  imports: [ProgressBarComponent, OnHoverTooltipComponent, LottieComponent, NgClass, NgIf],
  templateUrl: './locked-testable-products-info.component.html',
  styleUrls: ['./locked-testable-products-info.component.scss'],
})
export class LockedTestableProductsInfoComponent extends LocalizedComponent {
  @Input() product: LockedTestableProductData;

  public lottieOptions = {
    path: '/assets/animations/fire.json',
    autoplay: true,
    loop: true,
  };

  public testingProgressPercentage: number;

  public merchantsActivePercentage: number;

  public pcsToEnableLocking: number;

  public pcsSold: number;

  public tooltipStyles = {
    width: '250px',
    bottom: '30px',
    insetInlineEnd: '-11px',
    textAlign: 'start',
    backgroundColor: '#13161F',
  };

  public ngOnInit(): void {
    this.calcMerchantsActivePercentage();

    if (this.product.merchantInfo.orderTestingStarted) {
      this.calcTestingProgressPercentage();
      this.calcPcsToEnableLocking();
    }
  }

  private calcMerchantsActivePercentage(): void {
    const { totalMerchants, merchantsLeft } = this.product.orderTestingInfo;
    this.merchantsActivePercentage = ((totalMerchants - merchantsLeft) * 100) / totalMerchants;
  }

  private calcTestingProgressPercentage(): void {
    const { remainingStock, totalLockedStock } = this.product.merchantInfo.stockInfo!;
    this.pcsSold = totalLockedStock - remainingStock;
    this.testingProgressPercentage = ((totalLockedStock - remainingStock) * 100) / totalLockedStock;
  }

  private calcPcsToEnableLocking(): void {
    const { maxQuantity, succeededTestingLockRate } = this.product.orderTestingInfo;

    const initialPcsToEnableLocking = Math.round(maxQuantity * succeededTestingLockRate);

    this.pcsToEnableLocking = Math.max(initialPcsToEnableLocking - this.pcsSold, 0);
  }
}
