import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { hardNavigateMiddleware } from '../v3/presentation/shared/router';
import { ProductCheckoutComponent } from './presentation/product-checkout/product-checkout.component';
import {
  LOCAL_STORAGE_QUERY_PARAMS_KEY,
  PRODUCT_CHECKOUT_URL,
} from './presentation/shared/constants';
import { AccountSharedLayoutComponent } from './presentation/shared/containers/account-shared-layout/account-shared-layout.component';
import { AuthLayoutComponent } from './presentation/shared/containers/auth-layout/auth-layout.component';
import { DefaultLayoutComponent } from './presentation/shared/containers/default-layout/default-layout.component';
import { PlainLayoutComponent } from './presentation/shared/containers/plain-layout/plain-layout.component';
import { authResolver } from './presentation/shared/guards/v2/resolvers/auth.resolver';
import { cartResolver } from './presentation/shared/guards/v2/resolvers/cart.resolver';
import { complaintSuggestionResolver } from './presentation/shared/guards/v2/resolvers/complaint-suggestion.resolver';
import { CreativesResolver } from './presentation/shared/guards/v2/resolvers/creatives.resolver';
import { incentiveProgramResolver } from './presentation/shared/guards/v2/resolvers/incentive-program.resolver';
import { KYCResolver } from './presentation/shared/guards/v2/resolvers/kyc.resolver';
import { learningCenterResolver } from './presentation/shared/guards/v2/resolvers/learning-center.resolver';
import { loggedOutResolver } from './presentation/shared/guards/v2/resolvers/logged-out.resolver';
import { LoyaltyResolver } from './presentation/shared/guards/v2/resolvers/loyalty.resolver';
import { merchantInsightsResolver } from './presentation/shared/guards/v2/resolvers/merchant-insights.resolver';
import { onboardingResolver } from './presentation/shared/guards/v2/resolvers/onboarding.resolver';
import { optInResolver } from './presentation/shared/guards/v2/resolvers/optin.resolver';
import { orderSummaryResolver } from './presentation/shared/guards/v2/resolvers/order-summary.resolver';
import { ordersResolver } from './presentation/shared/guards/v2/resolvers/orders.resolver';
import { preOrdersRequestsResolver } from './presentation/shared/guards/v2/resolvers/pre-orders-requests.resolver';
import { productsResolver } from './presentation/shared/guards/v2/resolvers/products.resolver';
import { profileResolver } from './presentation/shared/guards/v2/resolvers/profile.resolver';
import { questionnaireResolver } from './presentation/shared/guards/v2/resolvers/questionnaire.resolver';
import { ReferralResolver } from './presentation/shared/guards/v2/resolvers/referral.resolver';
import { resetWalletPasswordResolver } from './presentation/shared/guards/v2/resolvers/shared/reset-wallet-password.resolver';
import { shippingInfoResolver } from './presentation/shared/guards/v2/resolvers/shipping-info.resolver';
import { statisticsResolver } from './presentation/shared/guards/v2/resolvers/statistics.resolver';
import { storesResolver } from './presentation/shared/guards/v2/resolvers/stores.resolver';
import { walletResolver } from './presentation/shared/guards/v2/resolvers/wallet.resolver';
import { youcanIntegrationRedirectionResolver } from './presentation/shared/guards/v2/resolvers/youcan-integration-redirection.resolver';
import { zidIntegrationRedirectionResolver } from './presentation/shared/guards/v2/resolvers/zid-integration-redirection.resolver';
import { LocalStorageService } from './presentation/shared/services/local-storage.service';

export const ROUTES: Routes = [
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./presentation/shared/containers/logged-out-layout/terms-conditions.module').then(
        (m) => m.TermsAndConditionsModule,
      ),
    /**
     * We need to add pathMath as full, because be default, all routes will be
     * matched since the path in this case is '', and this is not ideal,
     * as we cannot gracefully guide the user to other paths, which include ''
     * in them by default
     */
    pathMatch: 'full',
  },
  {
    path: 'privacy-policy',
    component: PlainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./presentation/shared/pages/privacy-policy-page/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule,
          ),
      },
    ],
  },
  {
    path: '',
    resolve: [
      (route: ActivatedRouteSnapshot) => {
        const localStorageService = inject(LocalStorageService);
        if (route.queryParams && route.queryParams.referral) {
          localStorageService.setStorage(LOCAL_STORAGE_QUERY_PARAMS_KEY, route.queryParams);
        }
        return true;
      },
    ],
    loadChildren: () =>
      import('./presentation/shared/containers/logged-out-layout/logged-out-layout.module').then(
        (m) => m.LoggedOutLayoutModule,
      ),
    canActivate: [loggedOutResolver],
    canLoad: [loggedOutResolver],
    /**
     * We need to add pathMath as full, because be default, all routes will be
     * matched since the path in this case is '', and this is not ideal,
     * as we cannot gracefully guide the user to other paths, which include ''
     * in them by default
     */
    pathMatch: 'full',
  },
  {
    path: '',
    component: PlainLayoutComponent,
    children: [
      {
        path: 'reset-wallet-password/:id/:resetToken',
        loadChildren: () =>
          import('./presentation/wallet/reset-wallet-password/reset-wallet-password.module').then(
            (m) => m.ResetWalletPasswordModule,
          ),
        canActivate: [resetWalletPasswordResolver],
        canLoad: [resetWalletPasswordResolver],
      },
      {
        path: 'questionnaire/:name',
        loadChildren: () =>
          import('./presentation/questionnaire/questionnaire.module').then(
            (m) => m.QuestionnaireModule,
          ),
        canActivate: [questionnaireResolver],
        canLoad: [questionnaireResolver],
      },
      {
        path: 'onboarding',
        loadChildren: () =>
          import('./presentation/onboarding/onboarding.module').then((m) => m.OnboardingModule),
        canActivate: [onboardingResolver],
        canLoad: [onboardingResolver],
      },
      {
        path: 'user-verification',
        loadChildren: () =>
          import('./presentation/user-verification/user-verification.module').then(
            (m) => m.OptInModule,
          ),
        canActivate: [optInResolver],
        canLoad: [optInResolver],
      },
      {
        path: 'kyc',
        loadChildren: () => import('./presentation/kyc/kyc.module').then((m) => m.KYCModule),
        canActivate: [KYCResolver],
        canLoad: [KYCResolver],
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./presentation/auth/auth.module').then((m) => m.AuthModule),
        canLoad: [authResolver],
        canActivate: [authResolver],
      },
      {
        path: 'learning-center',
        loadChildren: () =>
          import('./presentation/learning-center/learning-center.module').then(
            (m) => m.LearningCenterModule,
          ),
        canLoad: [learningCenterResolver],
        canActivate: [learningCenterResolver],
      },
      {
        path: 'creatives',
        loadChildren: () =>
          import('./presentation/creatives/creatives.module').then((m) => m.CreativesModule),
        canLoad: [CreativesResolver],
        canActivate: [CreativesResolver],
      },
    ],
  },
  /* Old routes redirects */
  { path: 'authv2', redirectTo: 'auth' },
  { path: 'reset-password/:id/:resetToken', redirectTo: 'auth/reset-password/:id/:resetToken' },
  /* Old routes redirects end */

  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'products',
      },
      {
        path: 'cart',
        loadChildren: () => import('./presentation/cart/cart.module').then((m) => m.CartModule),
        canActivate: [cartResolver],
        canLoad: [cartResolver],
      },
      {
        path: 'complain-suggest',
        loadChildren: () =>
          import('./presentation/complain-suggest/complain-suggest.module').then(
            (m) => m.ComplainSuggestModule,
          ),
        canActivate: [complaintSuggestionResolver],
        canLoad: [complaintSuggestionResolver],
      },
      {
        path: '',
        component: AccountSharedLayoutComponent,
        children: [
          {
            path: 'profile',
            loadChildren: () =>
              import('./presentation/profile/profile.module').then((m) => m.ProfileModule),
            canLoad: [profileResolver],
            canActivate: [profileResolver],
          },
          {
            path: 'wallet',
            loadChildren: () =>
              import('./presentation/wallet/wallet.module').then((m) => m.WalletModule),
            canLoad: [walletResolver],
            canActivate: [walletResolver],
          },
          {
            path: 'orders',
            loadChildren: () =>
              import('./presentation/orders/orders.module').then((m) => m.OrdersModule),
            canLoad: [ordersResolver],
            canActivate: [ordersResolver],
          },
          {
            path: 'incentive-program',
            loadChildren: () =>
              import('./presentation/incentive-program/incentive-program.module').then(
                (m) => m.IncentiveProgramModule,
              ),
            canLoad: [incentiveProgramResolver],
            canActivate: [incentiveProgramResolver],
          },
          {
            path: 'pre-order-requests',
            loadChildren: () =>
              import('./presentation/pre-order-requests/pre-order-requests.module').then(
                (m) => m.PreOrderRequestsModule,
              ),
            canActivate: [preOrdersRequestsResolver],
            canLoad: [preOrdersRequestsResolver],
          },
          {
            path: 'product-checkout',
            component: ProductCheckoutComponent,
            // canActivate: [productCheckoutStoreRequestsResolver],
            canActivate: [hardNavigateMiddleware(PRODUCT_CHECKOUT_URL)],
            // canLoad: [productCheckoutStoreRequestsResolver],
          },
        ],
      },
      {
        path: 'info',
        loadChildren: () =>
          import('./presentation/shared/pages/shipping-info/shipping-info.module').then(
            (m) => m.ShippingInfoModule,
          ),
        canLoad: [shippingInfoResolver],
        canActivate: [shippingInfoResolver],
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./presentation/products/statistics/statistics/statistics.module').then(
            (m) => m.StatisticsModule,
          ),
        canLoad: [statisticsResolver],
        canActivate: [statisticsResolver],
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./presentation/products/products.module').then((m) => m.ProductsModule),
        canLoad: [productsResolver],
        canActivate: [productsResolver],
      },
      {
        path: 'pre-order',
        loadChildren: () =>
          import('./presentation/pre-order/pre-order.module').then((m) => m.PreOrderModule),
        canActivate: [preOrdersRequestsResolver],
        canLoad: [preOrdersRequestsResolver],
      },
      {
        path: 'zid-integration',
        loadChildren: () =>
          import('./presentation/stores/stores.module').then((m) => m.StoresModule),
        canActivate: [zidIntegrationRedirectionResolver],
        canLoad: [zidIntegrationRedirectionResolver],
      },
      {
        path: 'insights',
        loadChildren: () =>
          import('./presentation/merchant-insights/merchant-insights.module').then(
            (m) => m.MerchantInsightsModule,
          ),
        canActivate: [merchantInsightsResolver],
        canLoad: [merchantInsightsResolver],
      },
      {
        path: 'loyalty',
        loadChildren: () =>
          import('./presentation/loyalty-program/loyalty.module').then((m) => m.LoyaltyModule),
        canLoad: [LoyaltyResolver],
        canActivate: [LoyaltyResolver],
      },
      {
        path: 'referral',
        loadChildren: () =>
          import('./presentation/referral-program/referral.module').then((m) => m.ReferralModule),
        canLoad: [ReferralResolver],
        canActivate: [ReferralResolver],
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('./presentation/stores/stores.module').then((m) => m.StoresModule),
        canLoad: [storesResolver],
        canActivate: [storesResolver],
      },
      {
        path: 'youcan-integration',
        loadChildren: () =>
          import('./presentation/stores/stores.module').then((m) => m.StoresModule),
        canActivate: [youcanIntegrationRedirectionResolver],
        canLoad: [youcanIntegrationRedirectionResolver],
      },

      /**
       * Named routes.
       *
       * These will be those specific UI components which we need to persist all through
       * the application routing.
       */
      {
        path: 'order-summary/:period',
        outlet: 'popup',
        loadChildren: () =>
          import('./presentation/order-summary/order-summary.module').then(
            (m) => m.OrderSummaryModule,
          ),
        canActivate: [orderSummaryResolver],
        canLoad: [orderSummaryResolver],
      },

      /* Old routes redirects */
      { path: 'products-v2', redirectTo: 'products' },
      { path: 'category-products', redirectTo: 'products/category' },
      { path: 'product-details/:id', redirectTo: 'products/:id' },
      { path: 'catalog', redirectTo: 'products/catalog' },
      /* Old routes redirects end */
    ],
  },

  { path: '**', redirectTo: '' },
];
