// locked-testable-products-header Presenter

import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { lockedTestableProductsTrackEvents } from '@features/locked-testable-products/data';
import { user } from '@features/user/data';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { WEB_MARKETPLACE_V3_3 } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { combineLatest } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetLoyaltyProgressUseCase } from 'src/app/core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from 'src/app/core/usecases/loyalty-program/get-tiers-data.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { LockedTestableProductsHeaderSideEffect } from './locked-testable-products-header.side-effects';
import { LockedTestableProductsHeaderViewEvent } from './locked-testable-products-header.view-events';
import { LockedTestableProductsHeaderViewState } from './locked-testable-products-header.view-state';

@Injectable({ providedIn: 'root' })
export class LockedTestableProductsHeaderPresenter extends BasePresenter<
  LockedTestableProductsHeaderViewState,
  LockedTestableProductsHeaderViewEvent,
  LockedTestableProductsHeaderSideEffect
> {
  constructor(
    private _getLoyaltyTiersDataUseCase: GetLoyaltyTiersDataUseCase,
    private _getLoyaltyProgressUseCase: GetLoyaltyProgressUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    super();
  }

  protected defaultViewState(): LockedTestableProductsHeaderViewState {
    return {
      merchantTierData: {
        icon: '',
        label: { ar: '', en: '' },
      },
      marketplaceV3_3Enabled: false,
    };
  }

  protected onViewEvent(event: LockedTestableProductsHeaderViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.checkIfMarketplaceV3_3Enabled();

        const loyaltyTiersData$ = this._getLoyaltyTiersDataUseCase.execute();

        const loyaltyProgress$ = this._getLoyaltyProgressUseCase.execute();

        this.add(
          combineLatest([loyaltyTiersData$, loyaltyProgress$]).subscribe(
            ([loyaltyTiersData, loyaltyProgress]) => {
              const tierData = loyaltyTiersData.filter(
                (tier) => tier.index === loyaltyProgress.currentLoyaltyTierIndex,
              )[0];
              this.merge({
                merchantTierData: {
                  icon: tierData.icon,
                  label: tierData.displayName,
                },
              });
            },
          ),
        );
        break;
      }

      case 'TestingProductsLearnMoreClicked': {
        this._logMixpanelEventUseCase.execute({
          eventName: lockedTestableProductsTrackEvents.LEARN_MORE,
        });
        break;
      }

      case 'TestingProductsViewAllClicked': {
        this._logMixpanelEventUseCase.execute({
          eventName: lockedTestableProductsTrackEvents.VIEW_ALL,
        });
        break;
      }
    }
  }

  protected checkIfMarketplaceV3_3Enabled() {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V3_3).subscribe({
      next: (attribute) => {
        this.merge({
          marketplaceV3_3Enabled: featureAttributeAssign(attribute, user.id, country.code),
        });
      },
    });
  }
}
